import React, { useState } from "react";
import { Grid, Typography, Paper } from '@mui/material';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Box from "@mui/material/Box";

export default function PaymentComp({ eventData, setSuccess }) {    
    
    console.log("Event data in Payment new code before :", JSON.stringify(eventData), eventData.transaction);
    
    function sendToServer() {
        console.log("transaction before sent to SQS in payment comp:", (eventData));

        try {
            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }

    const initialOptions = {
        clientId: process.env.REACT_APP_PROD_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        "disable-funding": "paylater"
    };

    const [errorMessage, setErrorMessage] = useState('')
    const [orderId, setOrderId] = useState(false)

    const createOrder = (data, actions) => {
        var itemArray = [];

        if (eventData.transaction.quantities > 0) {
            itemArray[itemArray.length] = {
                name: eventData.eventType,
                description: eventData.eventType,
                unit_amount: {
                    currency_code: "USD",
                    value: "" + parseInt(eventData.transaction.itemPrice * eventData.transaction.quantities),
                },
                quantity: "1",
            }
        }
        console.log('items array donation:', eventData.transaction.donationAmount);
        if (eventData.transaction.donationAmount > 0) {
            itemArray[itemArray.length] = {
                name: "Donation Amount",
                description: "Donation item",
                unit_amount: {
                    currency_code: "USD",
                    value: eventData.transaction.donationAmount,
                },
                quantity: "1",
            }
        }
        console.log('items array fee:', eventData.feeEnabled);
        if (eventData.feeEnabled > 0) {
            itemArray[itemArray.length] = {
                name: "Credit Card processing Fee Amount",
                description: "Credit Card Fee item",
                unit_amount: {
                    currency_code: "USD",
                    value: ((parseInt(eventData.transaction.quantities) * eventData.transaction.itemPrice + parseInt(eventData.transaction.donationAmount)) * 0.03).toFixed(2),
                },
                quantity: "1",
            }
        }
        console.log('items array in create order 2:', itemArray, eventData.transaction.totalAmount, eventData.transaction.donationAmount);
        return actions.order.create({
            purchase_units: [
                {
                    description: eventData.eventType,
                    amount: {
                        value: eventData.transaction.totalAmount,
                        currency_code: "USD",
                        breakdown: {
                            item_total: {
                                value: eventData.transaction.totalAmount,
                                currency_code: "USD",
                            }
                        }
                    },

                    items: itemArray, /**/
                },
            ],
            payer: {
                name: {
                given_name: eventData.parent.firstName, // First Name from the form
                surname: eventData.parent.lastName // Last Name from the form
                },
                email_address: eventData.parent.email, //eventData.parent.email,
                phone: {
                phone_type: "MOBILE",  // Specify it's a mobile number
                phone_number: {
                    national_number: eventData.parent.mobileNumber  // Mobile number
                }
                },
            }
        })
            .then((orderId) => {
                setOrderId(orderId)
                return orderId
            })
    }
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details
            setSuccess(true);
            eventData.transaction.paymentStatus = 'paid';
            eventData.transaction.payerId = payer.payer_id;
            ///setEventData(eventData);
            console.log('Payer info : ', payer);
            console.log('on Approve Event data in payment comp : ', eventData);
            sendToServer();
        })
    }
    const onError = (data, actions) => {
        setErrorMessage("An error occured with your payment")
    }

    return (
        <div>
                <Grid container direction="column" alignItems="center" >
                    {                     
                                <Grid item>                         
                                    <span>
                                        <Box sx={{ width: '100%', maxWidth: 500 }}>
                                            <Typography variant="h6" align="left" gutterBottom>
                                                <b>If you want make any changes, please click "Back" button otherwise click "Paypal"
                                          button to complete the transactions.</b>
                                                <div><br /></div>
                                                <Paper style={{ padding: '16px', backgroundColor: '#dfe7e7', color: 'red', fontWeight: 'bolder' }} elevation={3}>
                                            NOTE: Incase if you don't see PayPal buttons, please use Chrome browser (or) Laptop (or) contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a></Paper>
                                          <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </span>
                                    <PayPalScriptProvider options={initialOptions}>
                                        <PayPalButtons style={{ layout: "vertical", label: "pay" }}
                                            createOrder={createOrder}
                                            onApprove={onApprove}
                                        />
                                    </PayPalScriptProvider>                              
                                </Grid>}
                </Grid>
        </div >
    );
}