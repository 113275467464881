import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { Grid, Typography, Paper } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ParentInfoComp from '../components/ParentInfoComp'
import PaymentComp from '../components/PaymentComp'
import logo from '../assets/images/cards/Chinmaya-Mission-Logo.gif';
import { DataContext } from '../App';


export default function ElementaryLockinForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 5,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        spouse: {
            memberType: "spouse",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        children: [{
            memberType: "child",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            foodAllergies: "",
            medicalAllergies: "",
            environmentalAllergies: "",
            prescriptionMedications: "",
            otcMedications: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        guest: [],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "",
            payerId: ""
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: [],
        eventType: 'Elementary School Lock-In',
        eventTypeId: 12,
        center: '',
        notes: '',
        sessionName: "",
        otherCenter: '',
        feeEnabled: 0,
        success: false
    });
    //const ticketPrice = process.env.REACT_APP_LABORDAY_RETREAT_PRICE;
    const [ticketPrice, setTicketPrice] = useState(0); 
    //const [success, setSuccess] = useState(eventData.success);
    //console.log("Success value :", success);


    
    const eventTypes = useContext(DataContext);
    console.log("use context Amount :", JSON.stringify(eventTypes), eventTypes.events[0].desc);


 
    useEffect(() => {
        eventTypes.events.map((events, index) => (
             events.name === 'Elementary School Lock-In' ? 
                setTicketPrice(events.price)                
             : ''               
        ));
    }, [])         // Empty dependency array ensures this runs once on mount
    console.log("Ticket price :", ticketPrice);

    console.log("Event data in Check-in form :", JSON.stringify(eventData), eventData);
    const [inputFields, setInputFields] = useState(eventData.transaction);
    const [studentFields, setStudentFields] = useState(eventData.children);
    const [feeEnabled, setFeeEnabled] = useState(0);
    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(inputFields.quantities * inputFields.itemPrice);

    const [otherCenterFlag, setOtherCenterFlag] = useState(false);
    const [noOfPeople, setNoOfPeople] = useState(1);
    const [addVolunteer, setAddVolunteer] = useState();    
    
    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
    }
    total();
    //console.log("Donation, totalamounts at the end:", (eventData), inputFields, donation, totalAmount);
    function handleCenterChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
        if (e.target.value === 'Other') {
            setOtherCenterFlag(true);
        } else {
            setOtherCenterFlag(false);
        }
    }
    useEffect(() => {
        eventData.transaction.quantities = noOfPeople;
        eventData.transaction.itemPrice = ticketPrice;
        if (feeEnabled) {
            setTotalAmount(((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.00);
        }

        eventData.transaction.totalAmount = totalAmount;
    }, [noOfPeople, donation, feeEnabled, ticketPrice])

    const handleStudentChange = (e, index) => {
        //    console.log("Event data in Student handle form :", (studentFields));        
        let newForm = [...studentFields];
        newForm[index][e.target.name] = e.target.value;
        setStudentFields(newForm);
        eventData.children = newForm;        
    }
    const handleAddFields = () => {
        setStudentFields([...studentFields, {
            memberType: "child", firstName: "", lastName: "", grade: "", gender: "", age: 0, email: ""
        }]);
        setNoOfPeople(noOfPeople + 1);
    };

    const handleRemoveFields = (index) => {
        const values = [...studentFields];
        values.splice(index, 1);
        setStudentFields(values);
        eventData.children = values;
        setNoOfPeople(noOfPeople - 1);
    };

    const checkoutHandler = (e) => {
        e.preventDefault();
        //    console.log("transaction before sent to SQS:", (inputFields));
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };

    function sendToServer() {
        console.log("transaction before sent to SQS in lock-in:", (eventData));

        try {
            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }

    function feeSwitchHandler(e) {
        setFeeEnabled(e.target.checked);
        eventData.feeEnabled = e.target.checked ;
        //console.log("Event data feeSwitchHandler :", (eventData.feeEnabled));
    }
    function volunteerSwitchHandler(e) {
        setAddVolunteer(e.target.checked);
        eventData.parent.volunteer = e.target.checked ? "true" : "false";
        console.log("Event data volunteerSwitchHandler :", (eventData.parent));
    };

    function handleBack() {
        setCheckout(false);
    }

    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) });
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
        }
    };
 
    const [success, setSuccess] = useState(false)
    const [checkout, setCheckout] = useState(false)

    return (
        <div>
            <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Event Registration System'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid>
            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" alignItems="center" >
                    {
                        checkout ?
                            success  ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for the Registration'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive an email confirmation to this address <b> [ {eventData.parent.email} ] </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                
                                <Grid item>
                                    <PaymentComp eventData={eventData} setSuccess={setSuccess}/>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                            :
                            <div>
                                <Grid item xs={12} sm={12}>
                                <Paper elevation={3}>
                                <Paper style={{ padding: '16px', backgroundColor: '#dfe7e7', color: 'black' }} elevation={3}>
                                    <Grid item>
                                        <Box sx={{ width: '100%', minWidth: 300 }}>
                                            <Typography variant="body2" align="left" gutterBottom>

                                                <div><b>Event:</b> Elementary School Lock-in</div>
                                                <b>Where: </b>Chinmaya Saaket, 17701 Davenport Road, Dallas Texas 75252
                                            <div><b>Who Can Attend: </b>Grade 2 to 5</div>
                                                <div><b>When: </b>Friday Oct 18th 6:00 PM - 9:00 PM</div>
                                                <b>COST: </b>${ticketPrice} /  per Participant
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    </Paper>
                                    </Paper>
                                </Grid>
                                <Grid item>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small">
                                        <InputLabel id="demo-simple-select-standard-label">Select a Session</InputLabel>
                                        <Select
                                            id="standard"
                                            required
                                            name="center"
                                            value={eventData.center}
                                            onChange={e => setEventData({ ...eventData, [e.target.name]: e.target.value })}
                                            label="Center"
                                        >
                                            <MenuItem value="Chitrakoot-C1">Chitrakoot-C1</MenuItem>
                                            <MenuItem value="Chitrakoot-C3">Chitrakoot-C3</MenuItem>
                                            <MenuItem value="Frisco-F1">Frisco-F1</MenuItem>
                                            <MenuItem value="Frisco-F2">Frisco-F2</MenuItem>
                                            <MenuItem value="Frisco-F3">Frisco-F3</MenuItem>
                                            <MenuItem value="Saaket-S1">Saaket-S1</MenuItem>
                                            <MenuItem value="Saaket-S2">Saaket-S2</MenuItem>

                                        </Select>
                                    </FormControl>
                                    <div><br /></div>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems="center" >
                                    <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Participant Information</b>
                                        </Typography>
                                        </Paper>
                                        <div><br /></div>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container align="left" >
                                                {studentFields.map((studentFields, index) => (
                                                    <div key={index} align="left">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={6}>
                                                            <Paper elevation={3}>                                                           
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="outlined-basic"
                                                                    label="First Name"
                                                                    name="firstName"
                                                                    variant="outlined"
                                                                    value={studentFields.firstName}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                />  </Paper>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                            <Paper elevation={3} >
                                                                <TextField
                                                                    required
                                                                    fullWidth                                                                  
                                                                    
                                                                    id="lName"
                                                                    label="Last Name"
                                                                    name="lastName"
                                                                    variant="outlined"
                                                                    value={studentFields.lastName}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                /> </Paper> 
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                            <Paper elevation={0}> 
                                                                <FormControl variant="outlined" sx={{ m: 1, minWidth: 240 }} size="normal">
                                                                    <InputLabel id="demo-simple-select-standard-label">Grade</InputLabel>
                                                                    <Select
                                                                        required
                                                                        id="grade"
                                                                        name="grade"
                                                                        value={studentFields.grade}
                                                                        
                                                                        onChange={(e) => handleStudentChange(e, index)}
                                                                        label="Grade"
                                                                    >
                                                                        
                                                                        <MenuItem value="Grade-2">Grade-2</MenuItem>
                                                                        <MenuItem value="Grade-3">Grade-3</MenuItem>
                                                                        <MenuItem value="Grade-4">Grade-4</MenuItem>
                                                                        <MenuItem value="Grade-5">Grade-5</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                            
                                                                <FormControl variant="outlined" sx={{ m: 1, minWidth: 240 }} size="normal">
                                                                    <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
                                                                    <Select
                                                                        required
                                                                        id="standard"
                                                                        name="gender"
                                                                        fullwidth
                                                                        value={studentFields.gender}
                                                                        onChange={(e) => handleStudentChange(e, index)}
                                                                        label="Gender"
                                                                    >
                                                                        <MenuItem value="Male">Male</MenuItem>
                                                                        <MenuItem value="Female">Female</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                              
                                                            </Grid>
                                                            
                                                            
                                                            
                                                            <Grid  item xs={12} sm={12}>
                                                                <Paper elevation={3}>
                                                                <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>

                                                                <Typography variant="h7" align="left" gutterBottom style={{ fontWeight: 'bold' }}>                                                                    
                                                                    Participant Allergy Information
                                                                    </Typography>
                                                                    </Paper>
                                                                    <div><br /></div>
                                                                    <Typography variant="body1" align="left" gutterBottom>
                                                                    <Typography><b>Please indicate "Yes" or "No".</b> If "Yes" to any of the questions below, provide detailed information.</Typography>
                                                                    <div><br /></div>
                                                                    <Typography variant="body1">Does participant have food allergies? Ex: Gluten, Soy, Dairy (various forms of dairy) or Nuts (which nuts), or other foods</Typography>
                                                                        <TextField 
                                                                        required
                                                                        fullWidth
                                                                        multiline
                                                                        color='secondary'
                                                                        id="allergies"
                                                                        label="Your answer"
                                                                        name="foodAllergies"
                                                                        variant="outlined"
                                                                        value={studentFields.foodAllergies}
                                                                        onChange={(e) => handleStudentChange(e, index)}
                                                                        />
                                                                        </Typography>
                                                                    <div><br /></div>                                                                    
                                                                <div><br /></div>
                                                                    </Paper>
                                                            </Grid>
                                                            <div><br /></div>
                                                                                          
                                                            
                                                            <Grid item xs={12} sm={12}>
                                                                <div><br /></div>                                                                
                                                                <Paper elevation={3} variant="outlined">
                                                                <b>Add/Delete Additional Participant</b>
                                                                {index >= 1 ?
                                                                    <IconButton onClick={() => handleRemoveFields(index)}>
                                                                        <DeleteIcon />
                                                                    </IconButton> : ''
                                                                }
                                                                <IconButton onClick={() => handleAddFields(index)}>
                                                                    <PersonAddIcon />
                                                                </IconButton>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}

                                            </Grid>
                                        </Grid>
                                        </Paper>
                                    </Grid>


                                </Grid>
                                
                                <div><br /></div>                               
                           
                                <Paper elevation={3}>                                     
                                        
                                <ParentInfoComp eventData={eventData} />
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={addVolunteer} />} label="Are you interested to Volunteer"
                                            value={addVolunteer} size="small" onChange={volunteerSwitchHandler} />
                                    </FormGroup>
                                </Grid>
                                <div><br /></div>
                                </Paper>
                                
                                <div><br /></div>
                                <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Payment Information</b>
                                        </Typography>
                                </Paper>
                                <div><br /></div>
                                <Grid container direction="row" spacing={2} >
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="Quantity"
                                            fullWidth
                                            disabled
                                            label="Quantities #"
                                            type="number"
                                            name="Quantities"
                                            variant="standard"
                                            value={inputFields.quantities}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            number
                                            id="donation"
                                            label="Additional Donation ($)"
                                            type="number"
                                            name="donation"
                                            variant="standard"
                                            value={donation}
                                            onChange={handleDonation}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={feeEnabled} />} label="Add 3% to cover for CC fees"
                                                value={feeEnabled} size="small" onChange={feeSwitchHandler} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="totalAmountField"
                                            label="Total Amount($)"
                                            type="number"
                                            name="totalAmount"
                                            variant="standard"
                                            inputProps={{ style: { fontSize: 20 } }} // font size of input text
                                            InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
                                            value={totalAmount}
                                        />
                                    </Grid>
                                </Grid>
                                </Paper>

                                <Grid container direction="column" spacing={5}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Checkout</Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                    }
                </Grid>
            </form>
        </div >
    );
}