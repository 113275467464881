import React, { useState, useEffect, useContext } from 'react';
import logo from '../assets/images/cards/Chinmaya-Mission-Logo.gif';
import donation from '../assets/images/cards/GuruDakshina.jpg'
import chykcon from '../assets/images/cards/Chykcon.jpg'
import { Grid, Typography, Paper } from '@mui/material';
import FormCard from '../components/FormCard';
import UserList from '../components/FetchEvents';
import DonationForm from './DonationForm';
import { DataContext } from '../App';

export default function EventTypesForm({ eventData, setEventData }) {
    console.log("Event data in Banner 1 :", eventData, JSON.stringify(eventData));

    const { setEvents } = useContext(DataContext);

    const [dataFields, setDataFields] = useState ([       
                       
    
    {
        "createdBy": "admin",
        "createdAt": 1727301575000,
        "updatedBy": "admin",
        "updatedAt": 1727301575000,
        "eventTypeId": 15,
        "name": "Banquet",
        "desc": "Banquet",
        "category": "event",
        "displayOrder": 12,
        "imagePath": "../assets/images/cards/Banquet.jpg",
        "regStartDate": 1725752292000,
        "regEndDate": 1729467492000,
        "totalCapacity": 100,
        "price": 125.0,
        "discountedPrice": null,
        "discountedPriceExpirationTime": null,
        "eventYear": 2024,
        "route": "/Banquet"
    }, 
                    {
                        "createdBy": "admin",
                        "createdAt": 1725836791000,
                        "updatedBy": "admin",
                        "updatedAt": 1725836791000,
                        "eventTypeId": 11,
                        "name": "Chykcon",
                        "desc": "Event: CHYK CON 2024\r\nWhere: Chinmaya Mangalam | 10470 FM744, Barry TX, 75102\r\nWho Can Attend: Grade 12 and CHYK\r\nWhen: Wed, November 27th - Sun, December 1, 2024 (Overnight 4 Nights)\r\nCOST: $250/ per Participant\r",
                        "category": "event",
                        "displayOrder": 7,
                        "imagePath": "../assets/images/cards/Chykcon.jpg",
                        "regStartDate": 1725752292000,
                        "regEndDate": 1732145892000,
                        "totalCapacity": 100,
                        "price": 250.0,
                        "discountedPrice": null,
                        "discountedPriceExpirationTime": null,
                        "eventYear": 2024,
                        "route": "/ChykconForm"
                    },
                    {
                        "createdBy": "admin",
                        "createdAt": 1725506896000,
                        "updatedBy": "admin",
                        "updatedAt": 1725506896000,
                        "eventTypeId": 1,
                        "name": "Donation",
                        "desc": "Donation",
                        "category": "event",
                        "displayOrder": 1,
                        "imagePath": "../assets/images/cards/GuruDakshina.jpg",
                        "regStartDate": 1706485092000,
                        "regEndDate": 1803857892000,
                        "totalCapacity": 1000,
                        "price": 200.0,
                        "discountedPrice": null,
                        "discountedPriceExpirationTime": null,
                        "eventYear": 2024,
                        "route": "/DonationForm"
                    }
                                            
            ]);
    
            setEvents (dataFields);
    //console.log("Event data in Banner 2 :", dataFields, JSON.stringify(dataFields), dataFields[0].name);
    /*useEffect(() => {
        // Fetch data from API when component mounts
        fetch("https://6f0lsoh345.execute-api.us-east-1.amazonaws.com/dev/eventTypes")
          .then((response) => response.json()) // Parse JSON from the response
          .then((dataFields) => {
            setDataFields(dataFields);  // Set the data to state
          })
          .catch((error) => console.error('Error fetching data:', error));
      }, []);
      console.log("Event data in Banner 2 :", dataFields, JSON.stringify(dataFields), dataFields[0].name);
*/
    return (
        
        <div>
            
            
            <Grid container alignItems="center">
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Event Registration System'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid>
                
                <Grid container spacing={2} style={{ padding: '20px' }}>
                    {dataFields.map((dataField, index) => (
                    dataField.name === 'Donation' ?
                    <Grid item xs={12} sm={6}>
                        <FormCard
                            title={dataField.name}                        
                            route={dataField.route}
                            buttonName="Donate"
                            eventImage={dataField.imagePath}
                            />
                    </Grid>                    
                    : dataField.name === 'Banquet' ?
                    <Grid item xs={12} sm={6}>
                        <FormCard
                            title={dataField.name}                        
                            route={dataField.route}
                            buttonName="Purchase"
                            eventImage={dataField.imagePath}
                            />
                    </Grid>
                    :
                    <Grid item xs={12} sm={6}>
                        <FormCard
                            title={dataField.name}                        
                            route={dataField.route}
                            buttonName="Register"
                            eventImage={dataField.imagePath}
                            />
                    </Grid>
                    ))}
                    
                    {/* Add more cards as needed */}
                </Grid>               
                          
            </Grid>
            
        </div>
    );

}
