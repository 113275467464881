import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { Grid, Typography, Paper } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import logo from '../assets/images/cards/Chinmaya-Mission-Logo.gif';
import { DataContext } from '../App';

export default function ReportForm() {
    
    const [reportData, setReportData] = useState({
            eventType: "",
            emails: [""]
        });
    //const [emailAddress, setEmailAddress] = useState(reportData.emails);
    const [emailAddress, setEmailAddress] = useState([""]);

    
    //const eventTypes = useContext(DataContext);

    console.log("report data form :", JSON.stringify(reportData), reportData);
      
    const checkoutHandler = (e) => {
        e.preventDefault();
        //    console.log("transaction before sent to SQS:", (inputFields));
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };
    /*
    function handleChange(e) {
        //Handle any changes to the text fields
        setReportData({ ...reportData, [e.target.name]: e.target.value });
    }
    */
    const handleChange = (e, index) => {
        let newForm = [...emailAddress];
        newForm[index] = e.target.value;
        setEmailAddress(newForm);
        reportData.emails = newForm;
    }

    function sendToServer() {
        console.log("transaction before sent to SQS:", (reportData));

        try {
            //const url = process.env.REACT_APP_SQS_URL;
            const url = "https://7s66ceblk7.execute-api.us-east-1.amazonaws.com/v2/reports";


            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reportData),
            });
            setSuccess(true);
            console.log('API response:', reportData);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }
 
    function handleBack() {
        setCheckout(false);
    }
   

    const [success, setSuccess] = useState(false)
    const [checkout, setCheckout] = useState(false)

    return (
        <div>
            
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Report System'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid>  
            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" alignItems="center" >
                    {
                        checkout ?
                            success ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for your request.'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive {reportData.eventType} report to this address <b> [ {reportData.emails} ] </b>
                                        </Typography>
                                    </Box>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                                
                                :
                                <Grid item>                                    
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                            :
                            <div>
                                <Grid item xs={12} sm={12}>
                                <Paper elevation={3}>
                                <Paper style={{ padding: '16px', backgroundColor: '#dfe7e7', color: 'black' }} elevation={3}>
                                    <Grid item>
                                        <Box sx={{ width: '100%', minWidth: 300 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                                <div><b>Please select Event type from the drop-down and provide email address. An Email will be sent with the report
                                                     as attachement</b></div>                                                
                                                <div><br /></div>
                                            </Typography>                                            
                                        </Box>
                                    </Grid>
                                    </Paper>
                                    </Paper>
                                </Grid>
                                
                                <div><br /></div>
                                

                                <Grid item>
                                    <Grid container alignItems="left" >
                                    <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Report System</b>
                                        </Typography>
                                        </Paper>
                                        <div><br /></div>
                                        <Grid item xs={12} sm={12}>
                                        <Grid container align="left" spacing={2}>                                       
                                            
                                            <Grid item xs={12} sm={12}>
                                                            <Paper elevation={3}> 
                                                                <FormControl variant="outlined" sx={{ m: 1, minWidth: 340 }} size="normal">
                                                                    <InputLabel id="demo-simple-select-standard-label">Event Type</InputLabel>
                                                                    <Select
                                                                        required
                                                                        id="eventType"
                                                                        name="eventType"
                                                                        value={reportData.eventType}                                                                        
                                                                        onChange={e => setReportData({ ...reportData, [e.target.name]: e.target.value })}
                                                                        label="Event Type"
                                                                    >
                                                                        
                                                                        <MenuItem value="Chykcon">Chykcon</MenuItem>
                                                                        <MenuItem value="Middle School Lock-In">Middle School Lock-In</MenuItem>
                                                                        <MenuItem value="Banquet">Banquet</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                </Paper>
                                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                {emailAddress.map ((emailAddress, index) => (
                                                    <div key={index} align="left">
                                                    <Grid container spacing={2} >

                                                        <Grid item xs={12} sm={12}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    type="email"
                                                    fullWidth
                                                    required
                                                    id="email"
                                                    label="Email"
                                                    variant="outlined"
                                                    name="emails"
                                                    value={emailAddress.emails}
                                                    onChange={(e) => handleChange(e, index)}
                                                    />
                                            </Paper>
                                            </Grid>
                                            </Grid>
                                            </div> ))}
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>                             
                                                                
                                <div><br /></div>
                                <Grid container direction="column" spacing={5}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Send Email</Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                        </Typography>
                                    </Grid>
                                </Grid>                                
                            </div>
                    }
                </Grid>
            </form>
        </div >
    );
}