import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { Grid, Typography, Paper } from '@mui/material';
import Divider from "@mui/material/Divider";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import PaymentComp from '../components/PaymentComp'
import ParentInfoComp from '../components/ParentInfoComp'
import RetreatRulesComp from '../components/RetreatRulesComp'
import logo from '../assets/images/cards/Chinmaya-Mission-Logo.gif';
import { DataContext } from '../App';


export default function ChikconForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 5,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        spouse: {
            memberType: "spouse",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        children: [{
            memberType: "child",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            foodAllergies: "",
            medicalAllergies: "",
            environmentalAllergies: "",
            prescriptionMedications: "",
            otcMedications: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        guest: [],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "",
            payerId: ""
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: [],
        eventType: 'Chykcon',
        eventTypeId: 11,
        center: '',
        notes: '',
        sessionName: "",
        otherCenter: '',
        feeEnabled: 0,
        success: false
    });
    //const ticketPrice = process.env.REACT_APP_LABORDAY_RETREAT_PRICE;
    const [ticketPrice, setTicketPrice] = useState(0); 
    
    const eventTypes = useContext(DataContext);
    console.log("use context Amount :", DataContext.events, JSON.stringify(eventTypes), eventTypes.events[0]);
    useEffect(() => {
        eventTypes.events.map((dummy, index) => (
            dummy.name === 'Chykcon' ? 
                setTicketPrice(dummy.price) : ''))  ;              
           
    }, []) 
    console.log("Ticket price :", ticketPrice);

    console.log("Event data in Payment form :", JSON.stringify(eventData), eventData);
    const [inputFields, setInputFields] = useState(eventData.transaction);
    const [medicalFields, setMedicalFields] = useState(eventData.medical);
    const [studentFields, setStudentFields] = useState(eventData.children);
    const [feeEnabled, setFeeEnabled] = useState(0);
    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(inputFields.quantities * inputFields.itemPrice);

    const [otherCenterFlag, setOtherCenterFlag] = useState(false);
    const [noOfPeople, setNoOfPeople] = useState(1);

    

    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
    }
    total();
    //console.log("Donation, totalamounts at the end:", (eventData), inputFields, donation, totalAmount);
    function handleCenterChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
        if (e.target.value === 'Other') {
            setOtherCenterFlag(true);
        } else {
            setOtherCenterFlag(false);
        }
    }
    useEffect(() => {
        eventData.transaction.quantities = noOfPeople;
        eventData.transaction.itemPrice = ticketPrice;
        if (feeEnabled) {
            setTotalAmount(((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.00);
        }

        eventData.transaction.totalAmount = totalAmount;
    }, [noOfPeople, donation, feeEnabled, ticketPrice])

    const handleStudentChange = (e, index) => {
        //    console.log("Event data in Student handle form :", (studentFields));        
        let newForm = [...studentFields];
        newForm[index][e.target.name] = e.target.value;
        setStudentFields(newForm);
        eventData.children = newForm;        
    }
    const handleAddFields = () => {
        setStudentFields([...studentFields, {
            memberType: "child", firstName: "", lastName: "", grade: "", gender: "", age: 0, email: ""
        }]);
        setNoOfPeople(noOfPeople + 1);
    };

    const handleRemoveFields = (index) => {
        const values = [...studentFields];
        values.splice(index, 1);
        setStudentFields(values);
        eventData.children = values;
        setNoOfPeople(noOfPeople - 1);
    };

    function handleMedicalChange(e) {
        let newForm = { ...medicalFields };
        newForm[e.target.name] = e.target.value;
        setMedicalFields(newForm);
        eventData.medical = newForm;
    }

    const checkoutHandler = (e) => {
        e.preventDefault();
        //    console.log("transaction before sent to SQS:", (inputFields));
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };

    function sendToServer() {
        console.log("transaction before sent to SQS:", (eventData));

        try {
            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }

    function feeSwitchHandler(e) {
        setFeeEnabled(e.target.checked);
        eventData.feeEnabled = e.target.checked ;
    }

    function handleBack() {
        setCheckout(false);
    }


    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) });
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
        }
    };

    const initialOptions = {
        clientId: process.env.REACT_APP_PROD_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        "disable-funding": "paylater",
    };

    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [orderId, setOrderId] = useState(false)
    const [checkout, setCheckout] = useState(false)

/*
    const createOrder = (data, actions) => {
        var itemArray = [];

        if (eventData.transaction.quantities > 0) {
            itemArray[itemArray.length] = {
                name: "CHYK CON ",
                description: "CHYK CON",
                unit_amount: {
                    currency_code: "USD",
                    value: "" + parseInt(inputFields.itemPrice * inputFields.quantities),
                },
                quantity: "1",
            }
        }
        console.log('items array donation:', eventData.transaction.donationAmount);
        if (eventData.transaction.donationAmount > 0) {
            itemArray[itemArray.length] = {
                name: "Donation Amount",
                description: "Donation item",
                unit_amount: {
                    currency_code: "USD",
                    value: eventData.transaction.donationAmount,
                },
                quantity: "1",
            }
        }
        console.log('items array fee:', feeEnabled);
        if (feeEnabled > 0) {
            itemArray[itemArray.length] = {
                name: "Credit Card processing Fee Amount",
                description: "Credit Card Fee item",
                unit_amount: {
                    currency_code: "USD",
                    value: ((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(eventData.transaction.donationAmount)) * 0.03).toFixed(2),
                },
                quantity: "1",
            }
        }
        console.log('items array in create order 2:', itemArray, eventData.transaction.totalAmount, eventData.transaction.donationAmount);
        return actions.order.create({
            purchase_units: [
                {
                    description: "CHYK CON",
                    amount: {
                        value: eventData.transaction.totalAmount,
                        currency_code: "USD",
                        breakdown: {
                            item_total: {
                                value: eventData.transaction.totalAmount,
                                currency_code: "USD",
                            }
                        }
                    },

                    items: itemArray, 
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        })
            .then((orderId) => {
                setOrderId(orderId)
                return orderId
            })
    }
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details
            setSuccess(true);
            setCheckout(true);
            eventData.transaction.paymentStatus = 'paid';
            eventData.transaction.payerId = payer.payer_id;
            console.log('Payer info : ', payer);
            console.log('on Approve Event data : ', eventData);
            sendToServer();
        })
    }
    const onError = (data, actions) => {
        setErrorMessage("An error occured with your payment")
    }
*/
    return (
        <div>
            <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Event Registration System'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid>
            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" alignItems="center" >
                    {
                        checkout ?
                            success ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for the Registration'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive an email confirmation to this address <b> [ {eventData.parent.email} ] </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                <Grid item>
                                    <PaymentComp eventData={eventData} setEventData={setEventData} setSuccess={setSuccess}/>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>                                    
                                </Grid>
                            :
                            <div>
                                <Grid item xs={12} sm={12}>
                                    <Grid item>
                                        <Box sx={{ width: '100%', minWidth: 300 }}>
                                            <Typography variant="body2" align="left" gutterBottom>
                                                <div><b>Event:</b> CHYK CON 2024</div>
                                                <b>Where: </b>Chinmaya Mangalam | 10470 FM744, Barry TX, 75102
                                            <div><b>Who Can Attend: </b>Grade 12 and CHYK</div>
                                                <div><b>When: </b>Wed, November 27th - Sun, December 1, 2024 (Overnight 4 Nights)</div>
                                                <b>COST: </b>$250/ per Participant
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small">
                                        <InputLabel id="demo-simple-select-standard-label">Select a Center</InputLabel>
                                        <Select
                                            id="standard"
                                            required
                                            name="center"
                                            label="Center"
                                            value={eventData.center}
                                            onChange={handleCenterChange}
                                        >
                                            <MenuItem value="Chitrakoot">Chitrakoot</MenuItem>
                                            <MenuItem value="Frisco">Frisco</MenuItem>
                                            <MenuItem value="Saaket">Saaket</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>

                                        </Select>
                                        {otherCenterFlag && (
                                            <TextField
                                                fullWidth
                                                id="otherCenter"
                                                label="Enter Center :"
                                                sx={{ m: 1, minWidth: 150 }}
                                                variant="standard"
                                                name="otherCenter"
                                                value={eventData.otherCenter}
                                                onChange={e => setEventData({ ...eventData, [e.target.name]: e.target.value })}
                                            />)}
                                    </FormControl>
                                    <div><br /></div>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems="center" >
                                    <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Participant Information</b>
                                        </Typography>
                                        </Paper>
                                        <div><br /></div>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container align="left" >
                                                {studentFields.map((studentFields, index) => (
                                                    <div key={index} align="left">
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={12} sm={6}>
                                                            <Paper elevation={3}>                                                           
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    id="outlined-basic"
                                                                    label="First Name"
                                                                    name="firstName"
                                                                    variant="outlined"
                                                                    value={studentFields.firstName}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                />  </Paper>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                            <Paper elevation={3} >
                                                                <TextField
                                                                    required
                                                                    fullWidth                                                                  
                                                                    
                                                                    id="lName"
                                                                    label="Last Name"
                                                                    name="lastName"
                                                                    variant="outlined"
                                                                    value={studentFields.lastName}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                /> </Paper> 
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                            <Paper elevation={0}> 
                                                                <FormControl variant="outlined" sx={{ m: 1, minWidth: 240 }} size="normal">
                                                                    <InputLabel id="demo-simple-select-standard-label">Grade</InputLabel>
                                                                    <Select
                                                                        required
                                                                        id="grade"
                                                                        name="grade"
                                                                        value={studentFields.grade}
                                                                        
                                                                        onChange={(e) => handleStudentChange(e, index)}
                                                                        label="Grade"
                                                                    >
                                                                        
                                                                        <MenuItem value="CHYK">CHYK</MenuItem>
                                                                        <MenuItem value="Grade-12">Grade-12</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                            
                                                                <FormControl variant="outlined" sx={{ m: 1, minWidth: 240 }} size="normal">
                                                                    <InputLabel id="demo-simple-select-standard-label">Gender</InputLabel>
                                                                    <Select
                                                                        required
                                                                        id="standard"
                                                                        name="gender"
                                                                        fullwidth
                                                                        value={studentFields.gender}
                                                                        onChange={(e) => handleStudentChange(e, index)}
                                                                        label="Gender"
                                                                    >
                                                                        <MenuItem value="Male">Male</MenuItem>
                                                                        <MenuItem value="Female">Female</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                              
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                            
                                                                <FormControl variant="outlined" sx={{ m: 1, minWidth: 240 }} size="normal">
                                                                    <InputLabel id="demo-simple-select-standard-label">T-Shirt Size</InputLabel>
                                                                    <Select
                                                                        required
                                                                        id="standard"
                                                                        name="tshirtSize"
                                                                        fullwidth
                                                                        variant="outlined"
                                                                        value={studentFields.tshirtSize}
                                                                        onChange={(e) => handleStudentChange(e, index)}
                                                                        label="Tshirt Size"
                                                                    >
                                                                        <MenuItem value="Adult-XL">Adult-2XL</MenuItem>
                                                                        <MenuItem value="Adult-XL">Adult-XL</MenuItem>
                                                                        <MenuItem value="Adult-L">Adult-L</MenuItem>
                                                                        <MenuItem value="Adult-M">Adult-M</MenuItem>
                                                                        <MenuItem value="Adult-S">Adult-S</MenuItem>                                                                       

                                                                    </Select>
                                                                </FormControl>
                                                                
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                            <Paper elevation={3}>
                                                                <TextField                                                                    
                                                                    fullWidth
                                                                    required
                                                                    id="mobile"
                                                                    label="Mobile Phone"
                                                                    name="mobileNumber"
                                                                    variant="outlined"
                                                                    value={studentFields.mobileNumber}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                />
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                            <Paper elevation={3}>
                                                                <TextField
                                                                    type="email"
                                                                    required
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    id="email" label="Email" 
                                                                    name="email"
                                                                    value={studentFields.email}
                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                />
                                                                </Paper>
                                                            </Grid>
                                                            
                                                            <Grid  item xs={12} sm={12}>
                                                                <Paper elevation={3}>
                                                                <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>

                                                                <Typography variant="h7" align="left" gutterBottom style={{ fontWeight: 'bold' }}>                                                                    
                                                                    Participant Allergy Information
                                                                    </Typography>
                                                                    </Paper>
                                                                    <div><br /></div>
                                                                    <Typography variant="body1" align="left" gutterBottom>
                                                                    <Typography><b>Please indicate "Yes" or "No".</b> If "Yes" to any of the questions below, provide detailed information.</Typography>
                                                                    <div><br /></div>
                                                                    <Typography variant="body1">Does participant have food allergies? Ex: Gluten, Soy, Dairy (various forms of dairy) or Nuts (which nuts), or other foods</Typography>
                                                                        <TextField 
                                                                        required
                                                                        fullWidth
                                                                        multiline
                                                                        color='secondary'
                                                                        id="allergies"
                                                                        label="Your answer"
                                                                        name="foodAllergies"
                                                                        variant="outlined"
                                                                        value={studentFields.foodAllergies}
                                                                        onChange={(e) => handleStudentChange(e, index)}
                                                                        />
                                                                    <div><br /></div>
                                                                    <Paper elevation={3}>
                                                                    <Typography variant="body1">Does participant have any allergies to medications? Ex: Penicillin, specific first aid ointments, etc.</Typography>
                                                                        <TextField
                                                                            required
                                                                            fullWidth
                                                                            multiline
                                                                            id="medicalAllergies"
                                                                            label="Your answer"
                                                                            name="medicalAllergies"
                                                                            variant="outlined"
                                                                            value={studentFields.medicalAllergies}
                                                                            onChange={(e) => handleStudentChange(e, index)}
                                                                        />
                                                                    </Paper>
                                                                    <div><br /></div>
                                                                    <Typography variant="body1">Does participant have any environmental allergies? Ex: latex, bee or fire ant stings, poison oak, poison ivy etc.</Typography>
                                                                    <Typography variant="body1">(anything that doesnot fall into food or medication allergies)</Typography>
                                                                        <TextField
                                                                                required
                                                                                fullWidth
                                                                                multiline
                                                                                id="environmentalAllergies"
                                                                                label="Your answer"
                                                                                name="environmentalAllergies"
                                                                                variant="outlined"
                                                                                value={studentFields.environmentalAllergies}
                                                                                onChange={(e) => handleStudentChange(e, index)}
                                                                            />
                                                                    <div><br /></div>
                                                                    <Typography variant="body1">Does participant take any prescription medications that on-site Doctors/care givers should be aware of?</Typography>
                                                                    <Typography variant="body1">our Doctores will take up prescription medication and administer as inidcated</Typography>
                                                                    <Typography variant="body1">Be as detailed as possible : include name, dosage, timings, with or without food etc.</Typography>
                                                                            <TextField
                                                                                required
                                                                                fullWidth
                                                                                multiline
                                                                                id="prescriptionMedications"
                                                                                label="Your answer"
                                                                                name="prescriptionMedications"
                                                                                variant="outlined"
                                                                                value={studentFields.prescriptionMedications}
                                                                                onChange={(e) => handleStudentChange(e, index)}
                                                                            />
                                                                    <div><br /></div>
                                                                    <Typography variant="body1">If needed, can Doctor/care giver administer Over the counter medications? 
                                                                    Ex: Tylenol, Cold or Cough Medicine, OTC Allergy medicine Ex: Claritin, Zyrtec, Benadryl etc.</Typography>
                                                                        <TextField
                                                                                required
                                                                                fullWidth
                                                                                multiline
                                                                                id="otcMedications"
                                                                                label="Your answer"
                                                                                name="otcMedications"
                                                                                variant="outlined"
                                                                                value={studentFields.otcMedications}
                                                                                onChange={(e) => handleStudentChange(e, index)}
                                                                            />
                                                                        
                                                                    </Typography>                                                                    
                                                                    
                                                                <div><br /></div>
                                                                    </Paper>
                                                            </Grid>
                                                            <div><br /></div>
                                                                                          
                                                            
                                                            <Grid item xs={12} sm={12}>
                                                                <div><br /></div>                                                                
                                                                <Paper elevation={3} variant="outlined">
                                                                <b>Add/Delete Additional Participant</b>
                                                                {index >= 1 ?
                                                                    <IconButton onClick={() => handleRemoveFields(index)}>
                                                                        <DeleteIcon />
                                                                    </IconButton> : ''
                                                                }
                                                                <IconButton onClick={() => handleAddFields(index)}>
                                                                    <PersonAddIcon />
                                                                </IconButton>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}

                                            </Grid>
                                        </Grid>
                                        </Paper>
                                    </Grid>


                                </Grid>
                                
                                <div><br /></div>                               
                           
                                <Paper elevation={3}>                                     
                                        
                                <ParentInfoComp eventData={eventData} />
                                <div><br /></div>
                                </Paper>
                                
                                <Grid item xs={12} sm={12}>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            * We will contact you primarily through email with camp information, and in case of emergencies
                                by phone. Please ensure that you regularly check or respond to the email account/ phone
                                number you have submitted above.
                                </Typography>
                                    </Box>
                                </Grid>
                                <div><br /></div>
                                <Grid container direction="column" align="left">
                                <Paper elevation={3}>
                                       <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>

                                                                <Typography variant="h7" align="left" gutterBottom style={{ fontWeight: 'bold' }}>                                                                    
                                                                    Medical Information
                                                                    </Typography>
                                                                    </Paper>
                                                                    <div><br /></div>                                  
                                    <Grid container spacing={4}>
                                                                         
                                        <Grid item xs={12} sm={6}>
                                        <Paper elevation={3}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="pcpName"
                                                label="Primay Care Physican"
                                                name="pcpName"
                                                variant="outlined"
                                                value={medicalFields.pcpName}
                                                onChange={handleMedicalChange}
                                            />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <Paper elevation={3}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="pcpmobile"
                                                label="Physican Phone #"
                                                name="pcpMobile"
                                                variant="outlined"
                                                value={medicalFields.pcpMobile}
                                                onChange={handleMedicalChange}
                                            />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                        <Paper elevation={3}>
                                            <TextField
                                                fullWidth
                                                id="medicalInsurance"
                                                label="Medical Insurance"
                                                name="medicalInsurance"
                                                variant="outlined"
                                                value={medicalFields.medicalInsurance}
                                                onChange={handleMedicalChange}
                                            />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <Paper elevation={3}>
                                            <TextField
                                                fullWidth
                                                id="groupNo"
                                                label="Group No"
                                                name="groupNo"
                                                variant="outlined"
                                                value={medicalFields.groupNo}
                                                onChange={handleMedicalChange}
                                            />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <Paper elevation={3}>
                                            <TextField
                                                fullWidth
                                                id="idNo"
                                                label="ID No (if any)"
                                                name="idNo"
                                                variant="outlined"
                                                value={medicalFields.idNo}
                                                onChange={handleMedicalChange}
                                            />
                                            </Paper>
                                        </Grid>                                        
                                        <Grid item xs={12} sm={12}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                            <Typography variant="h7" align="left" gutterBottom>
                                                <b>Release and Medical Authorization</b>
                                            </Typography>
                                            </Paper>
                                            <div><br /></div>                                          
                                            RELEASE OF LIABILITY - In consideration of Chinmaya Mission granting the above named
                                retreater permission to participate in the event activities including being transported to
                                and back from the event location, I hereby assume all risks of her/his personal injury (including
                                death) that may result from any event activity (including residence hall activities). As
                                parent/guardian/responsible party, I do indemnify, defend and hold harmless, Chinmaya Mission
                                and its officers, Volunteer parents and all participants in the event program from and against all
                                liability, including claims and suits at law or in equity, for injury, fatal or otherwise, which may
                                result from any negligence and/or the retreater taking part in activities.
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                        <Paper elevation={3}>
                                            <TextField
                                                id="medicalConsentSignedBy"
                                                required
                                                fullWidth
                                                label="Signature  (Type your Name) :"
                                                name="medicalConsentSignedBy"
                                                variant="outlined"
                                                value={medicalFields.medicalConsentSignedBy}
                                                onChange={handleMedicalChange}
                                            />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                            <Typography variant="h7" align="left" gutterBottom>
                                                <b>Consent For Treatment And/Or First Aid</b>
                                            </Typography>
                                            </Paper> 
                                            
                                            <div><br /></div>
                                            <p>
                                                In the event of injury or illness, I hereby
                                give my consent for medical treatment and permission to the assigned retreat doctor or any
                                adult event volunteer or any Hospital to supervise on-site first aid for minor injuries, and to a
                                licensed physician to hospitalize and secure proper treatment (including injections, anesthesia,
                                surgery, or other reasonable treatment and necessary procedures) for the retreater.
                                </p>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                        <Paper elevation={3}>
                                            <TextField
                                                id="treatmentConsentSignedBy"
                                                required
                                                fullWidth
                                                label="Signature (Type your Name) :"
                                                name="treatmentConsentSignedBy"
                                                variant="outlined"
                                                value={medicalFields.medicalConsentSignedBy}
                                                onChange={handleMedicalChange}
                                            />
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    </Paper>
                                </Grid>
                                <div><br /></div>
                                <RetreatRulesComp eventData={eventData} />
                                <div><br /></div>
                                <Paper elevation={3}>                                
                                        <Grid item xs={12} sm={12}>
                                        <Paper elevation={3}>
                                            <TextField
                                                id="medicalConsentSignedBy"
                                                required
                                                fullWidth
                                                label="Signature  (Type your Name) :"
                                                name="treatmentConsentSignedBy"
                                                variant="outlined"
                                                value={medicalFields.treatmentConsentSignedBy}
                                                onChange={handleMedicalChange}
                                            />
                                            </Paper>
                                        </Grid>
                                    </Paper>
                                <div><br /></div>
                                <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Payment Information</b>
                                        </Typography>
                                </Paper>
                                <div><br /></div>
                                <Grid container direction="row" spacing={2} >
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="Quantity"
                                            fullWidth
                                            disabled
                                            label="Quantities #"
                                            type="number"
                                            name="Quantities"
                                            variant="standard"
                                            value={inputFields.quantities}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            number
                                            id="donation"
                                            label="Additional Donation ($)"
                                            type="number"
                                            name="donation"
                                            variant="standard"
                                            value={donation}
                                            onChange={handleDonation}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={feeEnabled} />} label="Add 3% to cover for CC fees"
                                                value={feeEnabled} size="small" onChange={feeSwitchHandler} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="totalAmountField"
                                            label="Total Amount($)"
                                            type="number"
                                            name="totalAmount"
                                            variant="standard"
                                            inputProps={{ style: { fontSize: 20 } }} // font size of input text
                                            InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
                                            value={totalAmount}
                                        />
                                    </Grid>
                                </Grid>
                                </Paper>

                                <Grid container direction="column" spacing={5}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Checkout</Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                    }
                </Grid>
            </form>
        </div >
    );
}