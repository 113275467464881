import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { Grid, Typography, Paper } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import PaymentComp from '../components/PaymentComp'
import ChildCareComp from '../components/ChildCareComp'
import logo from '../assets/images/cards/Chinmaya-Mission-Logo.gif';
import { DataContext } from '../App';

export default function BanquetForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 5,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: false,
            volunteer: ""
        },
        spouse: {
            memberType: "spouse",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        children: [{
            memberType: "child",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            foodAllergies: "",
            medicalAllergies: "",
            environmentalAllergies: "",
            prescriptionMedications: "",
            otcMedications: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        guest: [],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "",
            payerId: ""
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: [],
        eventType: 'Banquet',
        eventTypeId: 15,
        center: 'other',
        notes: '',
        sessionName: "",
        otherCenter: "",
        feeEnabled: 0,
        success: false
    });
    //const ticketPrice = process.env.REACT_APP_NAADABINDU_PRICE;
    const eventTypes = useContext(DataContext);
    const [ticketPrice, setTicketPrice] = useState(0); 



    console.log("Event data in Payment form :", JSON.stringify(eventData), eventData);
    const [inputFields, setInputFields] = useState(eventData.transaction);
    const [parentFields, setParentFields] = useState(eventData.parent);

    const [feeEnabled, setFeeEnabled] = useState(0);
    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(inputFields.quantities * inputFields.itemPrice);

    const [otherDonationFlag, setOtherDonationFlag] = useState(false);

    const [noOfPeople, setNoOfPeople] = useState(inputFields.quantities);

    const [selectedAmount, setSelectedAmount] = useState(0);

    useEffect(() => {
        eventTypes.events.map((events, index) => (
             events.name === 'Banquet' ? 
                setTicketPrice(events.price)                
             : ''               
                ));       
   
    }, [])         // Empty dependency array ensures this runs once on mount
    
    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
    }
    total();
    //console.log("Donation, totalamounts at the end:", (eventData), inputFields, donation, totalAmount);
    
    useEffect(() => {
        eventData.transaction.quantities = noOfPeople;
        eventData.transaction.itemPrice = ticketPrice;
        if (feeEnabled) {
            setTotalAmount(((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.00);
        }

        eventData.transaction.totalAmount = totalAmount;
    }, [noOfPeople, donation, feeEnabled, ticketPrice])
  
   
    const checkoutHandler = (e) => {
        e.preventDefault();
        //    console.log("transaction before sent to SQS:", (inputFields));
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };

    function handleParentChange(e) {
        let newForm = { ...parentFields };
        newForm[e.target.name] = e.target.value;
        setParentFields(newForm);
        eventData.parent = newForm;
    }
    
    function handleChange(e) {
        //Handle any changes to the text fields
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }

    function sendToServer() {
        console.log("transaction before sent to SQS:", (eventData));

        try {
            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }
    function handlePeople(e) {
        setNoOfPeople(parseInt(e.target.value));
    }

    function feeSwitchHandler(e) {
        setFeeEnabled(e.target.checked);
        eventData.feeEnabled = e.target.checked ;
    }

    function handleBack() {
        setCheckout(false);
    }


    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) });
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
        }
    };

    const [success, setSuccess] = useState(false)
    const [checkout, setCheckout] = useState(false)

    return (
        <div>
            
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Event Registration System'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid>  
            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" alignItems="center" >
                    {
                        checkout ?
                            success ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for the Registration'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive an email confirmation to this address <b> [ {eventData.parent.email} ] </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                <Grid item>
                                    <PaymentComp eventData={eventData} setSuccess={setSuccess}/>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                            :
                            <div>
                                <Grid item xs={12} sm={12}>
                                <Paper elevation={3}>
                                <Paper style={{ padding: '16px', backgroundColor: '#dfe7e7', color: 'black' }} elevation={3}>
                                    <Grid item>
                                        <Box sx={{ width: '100%', minWidth: 300 }}>
                                            <Typography variant="body2" align="left" gutterBottom>
                                                <div><b>Event: Banquet</b></div>
                                                <b>Where: </b>The Westin Hotel - Stonebriar,  1549 Legacy Dr, Frisco, TX 75034
                                            <div><b>Who Can Attend: </b>Age 13 and Above*</div>
                                                <div><b>When: </b>Saturday December 7th @5:30 PM</div>
                                                <b>COST: </b>$125/ per Person
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    </Paper>
                                    </Paper>
                                </Grid>
                                
                                <div><br /></div>
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small">
                                        <InputLabel id="demo-simple-select-standard-label">Select a Center</InputLabel>
                                        <Select
                                            id="standard"
                                            required
                                            name="center"
                                            label="Center"
                                            value={eventData.center}
                                            onChange={e => setEventData({ ...eventData, [e.target.name]: e.target.value })}
                                        >
                                            <MenuItem value="Chitrakoot">Chitrakoot</MenuItem>
                                            <MenuItem value="Frisco">Frisco</MenuItem>
                                            <MenuItem value="Saaket">Saaket</MenuItem>
                                            <MenuItem value="Other">None</MenuItem>

                                        </Select>
                                        
                                    </FormControl>
                                    <div><br /></div>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems="left" >
                                    <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Customer Information</b>
                                        </Typography>
                                        </Paper>
                                        <div><br /></div>
                                        <Grid item xs={12} sm={12}>
                                        <Grid container align="left" spacing={2}>                                       
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    required
                                                    id="fName"
                                                    label="First Name"
                                                    name="firstName"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={parentFields.firstName}
                                                    onChange={handleParentChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    required
                                                    id="lName"
                                                    label="Last Name"
                                                    name="lastName"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={parentFields.lastName}
                                                    onChange={handleParentChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    required
                                                    id="mobile"
                                                    label="Mobile Phone"
                                                    name="mobileNumber"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={parentFields.mobileNumber}
                                                    onChange={handleParentChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    type="email"
                                                    fullWidth
                                                    required
                                                    id="email"
                                                    label="Email"
                                                    variant="outlined"
                                                    name="email"
                                                    value={parentFields.email}
                                                    onChange={handleParentChange}
                                                />
                                            </Paper>
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                
                                <div><br /></div>
                                
                                <Paper elevation={3}>                                
                                        <Grid item xs={12} sm={12}>
                                        <Paper elevation={3}>
                                            <TextField
                                                    fullWidth
                                                    number
                                                    id="tickets"
                                                    label="Enter Number of Tickets"
                                                    type="number"
                                                    inputProps={{ min: 1, max: 20 }}
                                                    name="quantities"
                                                    variant="outlined"
                                                    value={inputFields.quantities}
                                                    onChange={handlePeople}
                                                />
                                            
                                            </Paper>
                                        </Grid>
                                    </Paper>
                                <div><br /></div>
                                <Paper elevation={3}>
                                <Grid item>
                                        <ChildCareComp eventData={eventData} />
                                </Grid>
                                </Paper>
                                <div><br /></div>


                                <Grid item>
                                   <Grid container alignitems="left" spacing={2}>
                                   <Grid item xs={12} sm={12}>
                                    <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Payment</b>
                                        </Typography>
                                        </Paper>
                                        <div><br /></div>                                     

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            number
                                            id="donation"
                                            label="Additional Donation ($)"
                                            type="number"
                                            name="donation"
                                            variant="standard"
                                            value={donation}
                                            onChange={handleDonation}
                                        />
                                    </Grid>                                 
                                            
                                
                                    <Grid item xs={12} sm={12}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={feeEnabled} />} label="Add 3% to cover for Credit Card fees"
                                                value={feeEnabled} size="small" onChange={feeSwitchHandler} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                    <Paper elevation={3} >
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="totalAmountField"
                                            label="Total Amount($)"
                                            type="number"
                                            name="totalAmount"
                                            variant="standard"                                            
                                            value={totalAmount}
                                        />
                                        </Paper>
                                    </Grid>
                                    </Paper>
                                    </Grid>  
                                    </Grid>
                                </Grid>

                                <Grid container direction="column" spacing={5}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Checkout</Button>
                                    </Grid>
                                    <span>
                                        <div><b> * Free onsite child care for children age 12 and below </b></div>
                                    </span>
                                    <Grid item>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                        </Typography>
                                    </Grid>
                                </Grid>                                
                            </div>
                    }
                </Grid>
            </form>
        </div >
    );
}