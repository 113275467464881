import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { Grid, Typography, Paper } from '@mui/material';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import donationimage from '../assets/images/cards/Donation.jpg'
import logo from '../assets/images/cards/Chinmaya-Mission-Logo.gif';
import PaymentComp from '../components/PaymentComp'
import { DataContext } from '../App';

export default function DonationForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 5,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        spouse: {
            memberType: "spouse",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        children: [{
            memberType: "child",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            foodAllergies: "",
            medicalAllergies: "",
            environmentalAllergies: "",
            prescriptionMedications: "",
            otcMedications: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        guest: [],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "paid",
            payerId: ""
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: [],
        eventType: 'Donation',
        eventTypeId: 1,
        center: 'other',
        notes: '',
        sessionName: "",
        otherCenter: 'donation',
        feeEnabled: 0,
        success: false
    });
    const ticketPrice = process.env.REACT_APP_LABORDAY_RETREAT_PRICE;

    const events = useContext(DataContext);
    console.log("use context Amount :", JSON.stringify(events), events.events[2].price);


    console.log("Event data in Payment form :", JSON.stringify(eventData), eventData);
    const [inputFields, setInputFields] = useState(eventData.transaction);
    const [parentFields, setParentFields] = useState(eventData.parent);

    const [feeEnabled, setFeeEnabled] = useState(0);
    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(inputFields.quantities * inputFields.itemPrice);

    const [otherDonationFlag, setOtherDonationFlag] = useState(false);

    const [noOfPeople, setNoOfPeople] = useState(0);

    const [selectedAmount, setSelectedAmount] = useState(0);

    const handleSelect = (amount) => {
        setSelectedAmount(amount);
        console.log("Amount :", amount);
    }
    const handleSelectDonation = (e) => {
        setDonation(e.target.value);
        if (e.target.value === 'Other') {
            setOtherDonationFlag(true);
        } else {
            setOtherDonationFlag(false);
        }
        setSelectedAmount(e.target.value);
        console.log("Amount :", e.target.value);
    }


    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
    }
    total();
    //console.log("Donation, totalamounts at the end:", (eventData), inputFields, donation, totalAmount);
    
    useEffect(() => {
        eventData.transaction.quantities = noOfPeople;
        eventData.transaction.itemPrice = ticketPrice;
        if (feeEnabled) {
            setTotalAmount(((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.00);
        }

        eventData.transaction.totalAmount = totalAmount;
    }, [noOfPeople, donation, feeEnabled])
  
   
    const checkoutHandler = (e) => {
        e.preventDefault();
        //    console.log("transaction before sent to SQS:", (inputFields));
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };

    function handleParentChange(e) {
        let newForm = { ...parentFields };
        newForm[e.target.name] = e.target.value;
        setParentFields(newForm);
        eventData.parent = newForm;
    }
    
    function handleChange(e) {
        //Handle any changes to the text fields
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }

    function sendToServer() {
        console.log("transaction before sent to SQS:", (eventData));

        try {
            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }

    function feeSwitchHandler(e) {
        setFeeEnabled(e.target.checked);
        eventData.feeEnabled = e.target.checked ;
    }

    function handleBack() {
        setCheckout(false);
    }


    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) });
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
        }
    };

    const initialOptions = {
        clientId: process.env.REACT_APP_PROD_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        "disable-funding": "paylater",
    };

    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [orderId, setOrderId] = useState(false)
    const [checkout, setCheckout] = useState(false)


    const createOrder = (data, actions) => {
        var itemArray = [];

        if (eventData.transaction.quantities > 0) {
            itemArray[itemArray.length] = {
                name: "Event General Donation",
                description: "Event Donation Item",
                unit_amount: {
                    currency_code: "USD",
                    value: "" + parseInt(inputFields.itemPrice * inputFields.quantities),
                },
                quantity: "1",
            }
        }
        console.log('items array donation:', eventData.transaction.donationAmount);
        if (eventData.transaction.donationAmount > 0) {
            itemArray[itemArray.length] = {
                name: "Event Donation Amount",
                description: "Event Donation item",
                unit_amount: {
                    currency_code: "USD",
                    value: eventData.transaction.donationAmount,
                },
                quantity: "1",
            }
        }
        console.log('items array fee:', feeEnabled);
        if (feeEnabled > 0) {
            itemArray[itemArray.length] = {
                name: "Credit Card processing Fee Amount",
                description: "Credit Card Fee item",
                unit_amount: {
                    currency_code: "USD",
                    value: ((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(eventData.transaction.donationAmount)) * 0.03).toFixed(2),
                },
                quantity: "1",
            }
        }
        console.log('items array in create order 2:', itemArray, eventData.transaction.totalAmount, eventData.transaction.donationAmount);
        return actions.order.create({
            purchase_units: [
                {
                    description: "General Donation",
                    amount: {
                        value: eventData.transaction.totalAmount,
                        currency_code: "USD",
                        breakdown: {
                            item_total: {
                                value: eventData.transaction.totalAmount,
                                currency_code: "USD",
                            }
                        }
                    },

                    items: itemArray, /**/
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        })
            .then((orderId) => {
                setOrderId(orderId)
                return orderId
            })
    }
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details
            setSuccess(true);
            setCheckout(true);
            eventData.transaction.paymentStatus = 'paid';
            eventData.transaction.payerId = payer.payer_id;
            console.log('Payer info : ', payer);
            console.log('on Approve Event data : ', eventData);
            sendToServer();
        })
    }
    const onError = (data, actions) => {
        setErrorMessage("An error occured with your payment")
    }

    return (
        <div>            
            <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Event Registration System'}
                    </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
            </Grid>
                
                
            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" alignItems="center" >
                    {
                        checkout ?
                            success ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for the Donation'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive an email confirmation to this address <b> [ {eventData.parent.email} ] </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                <Grid item>
                                    <PaymentComp eventData={eventData} setEventData={setEventData} setSuccess={setSuccess}/>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                            :
                            <div>
                                <Grid item>
                                   <Grid container alignitems="left" spacing={2}>
                                   <Grid item xs={12} sm={12}>
                                    <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Donation</b>
                                        </Typography>
                                        </Paper>
                                        <div><br /></div>
                                        
                                        <Grid item xs={12} sm={12}>                                       
                                                <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="101"
                                                    value={donation}
                                                    onChange={handleSelectDonation}
                                                    name="radio-buttons-group"
                                                >
                                                    <FormControlLabel value="101" control={<Radio />} label="$101" />
                                                    <FormControlLabel value="501" control={<Radio />} label="$501" />
                                                    <FormControlLabel value="1116" control={<Radio />} label="$1116" />
                                                    <FormControlLabel value="Other" control={<Radio />} label="Other"/>
                                                </RadioGroup>
                                                </FormControl>                                                                                         
                                            </Grid>
                                        
                                            <Paper elevation={3}>                       
                                            {otherDonationFlag && (
                                                <TextField
                                                fullWidth
                                                number
                                                id="donation"
                                                label="Enter Donation Amount ($)"
                                                type="number"
                                                name="donation"
                                                variant="outlined"
                                                value={donation}
                                                onChange={handleDonation}
                                            />)}
                                        </Paper>
                                
                                    <Grid item xs={12} sm={12}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={feeEnabled} />} label="Add 3% to cover for Credit Card fees"
                                                value={feeEnabled} size="small" onChange={feeSwitchHandler} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                    <Paper elevation={3} >
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="totalAmountField"
                                            label="Total Amount($)"
                                            type="number"
                                            name="totalAmount"
                                            variant="standard"                                            
                                            value={totalAmount}
                                        />
                                        </Paper>
                                    </Grid>
                                    </Paper>
                                    </Grid>  
                                    </Grid>
                                </Grid>
                                
                                <div><br /></div>

                                <Grid item>
                                    <Grid container alignItems="left" >
                                    <Paper elevation={3}>
                                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h7" align="left" gutterBottom>
                                            <b>Donor Information</b>
                                        </Typography>
                                        </Paper>
                                        <div><br /></div>
                                        <Grid item xs={12} sm={12}>
                                        <Grid container align="left" spacing={2}>                                       
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    required
                                                    id="fName"
                                                    label="First Name"
                                                    name="firstName"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={parentFields.firstName}
                                                    onChange={handleParentChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    required
                                                    id="lName"
                                                    label="Last Name"
                                                    name="lastName"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={parentFields.lastName}
                                                    onChange={handleParentChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    required
                                                    id="mobile"
                                                    label="Mobile Phone"
                                                    name="mobileNumber"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={parentFields.mobileNumber}
                                                    onChange={handleParentChange}
                                                />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            <Paper elevation={3}>
                                                <TextField
                                                    type="email"
                                                    fullWidth
                                                    required
                                                    id="email"
                                                    label="Email"
                                                    variant="outlined"
                                                    name="email"
                                                    value={parentFields.email}
                                                    onChange={handleParentChange}
                                                />
                                            </Paper>
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                
                                <div><br /></div>
                                <Grid container direction="column" >
                                <Paper elevation={3}>
                                    <Grid item>
                                        <TextField
                                            id="notes"
                                            rows="2"                                            
                                            fullWidth
                                            multiline
                                            label="Notes :"
                                            name="notes"
                                            variant="outlined"
                                            value={eventData.notes}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    </Paper>
                                </Grid>
                                <div><br /></div>
                                
                                <Grid container direction="column" spacing={5}>
                                    <Grid item>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Checkout</Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                        </Typography>
                                    </Grid>
                                </Grid>                                
                            </div>
                    }
                </Grid>
            </form>
        </div >
    );
}