import React, { useState, useEffect } from 'react';
import logo from '../assets/banquet-banner.png';
import { Grid, Typography, Paper } from '@mui/material';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { orange } from '@mui/material/colors';


export default function RetreatRulesComp({ eventData }) {
    //    console.log("Event data in Parent Info change form 2 :", (eventData), (inputFields));
    
    return (
        <div>
            <React.Fragment>              
                
                <Paper elevation={3}>

                <Grid item xs={12} sm={12}>                                                        
                        <Paper style={{ padding: '16px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                            <Typography variant="h7" align="left" gutterBottom>
                                <b>Retreat Information and Rules</b>
                            </Typography>
                            </Paper>
                            <div><br /></div>                                           
                            <b>A copy of this section will be sent to your email for your reference</b>
                            <div><br /></div>

                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                >
                                <b>Please read all the Rules/Guidelines and sign below : </b>
                                </AccordionSummary>
                                <AccordionDetails>                                    
                                    <Typography variant="body2" align="left"><b>Arrival and Check-In at Chinmaya Mangalam:</b></Typography>
                                    <ul align='left'>
                                        <li>Upon arrival at Mangalam, attendees MUST check-in at the Dining Hall for room assignments and program instructions.</li>
                                        <li>If you need to use restroom  facilities upon arrival, please proceed to the Dome.</li>
                                        <li>Do NOT wander around the property by yourself. Wait to be escorted by a retreat volunteer /coordinator.</li>
                                        <li>Please park cars in designated "PARKING" areas only.</li>
                                    </ul>  
                                    <Typography variant="body2" align="left"><b>Important Rules and Guidelines:</b></Typography>
                                    <ul align='left'>
                                        <li>Once checked-in, attendees must NOT leave the retreat facility without specific permission from the Bramahacharins.</li>
                                        <li><b>There is a ZERO TOLERANCE  POLICY for the items described below.  Anyone found in violation will be immediately dismissed from the retreat.</b></li>
                                        <li>Boys & Girls are <b>NOT ALLOWED</b> to enter into each other's cabins.</li>
                                        <li>No Substance abuse: Smoking, vaping, alcohol, illegal drugs etc. are strictly prohibited on the entire Chinmaya Mangalam Property.</li>
                                        <li>No Weapons: Any kind of weapons are strictly prohibited on the entire Chinmaya Mangalam Property.</li>
                                        <li>No Food: No food including snacks or drinks (except water) are allowed in sleeping quarters. These attract insects and other small creatures and are potential hazards.</li>
                                        <li>No Electronics: Please refrain from bringing electronic devices, smart devices, including smart watch, headphones, gaming devices , tablets etc:  Chinmaya Mission will not be responsible for any loss or damage.</li> 
                                        <li>Cellphones: we will be collecting them at Check in at Mangalam and giving them back during Check out at Mangalam. In case parents need to contact their child, please reach out to the Brahmacharins.</li>
                                        <li>Brahmacharins' contact: Brahmachari Hari () </li>
                                        <li>Facilities: Do not modify the Air Conditioner Cooling and Heating settings. Notify the Facilities Coordinator or Retreat Coordinator if there are any issues.</li>
                                        <li>Medication: Attendees taking prescribed medications MUST provide the details on the registration form.</li>
                                    </ul>                                    
                                    <Typography variant="body2" align="left"><b>Safety Precautions:</b></Typography>
                                    <ul align='left'>                                    
                                        <li><b>Footwear:</b> Wear closed-toe shoes at all times when outside. Bathroom slippers and/or water shoes are required to be worn when using the bathroom facilities adjoining the cabins.</li>
                                        <li><b>Walking Paths:</b> Stick to well-defined walking paths for safety and avoid walking through shrubs, bushy areas, piles of dried leaves or woods as they may harbor poisonous snakes, scorpions, and other creatures.</li>
                                        <li><b>Wildlife:</b> Observe wildlife from a distance; do not approach or interact with them.</li>
                                        <li><b>Water Areas:</b> Stay away from all open water areas, including the lake and pond, at all times.</li>
                                        <li><b>Bridge:</b> Do not cross the unsafe bridge on the west end of the property.</li>
                                        <li><b>Signs and Designated Areas:</b> Follow all posted signs and stay within designated areas. Respect the boundaries indicated on the map; some areas are off-limits.</li>
                                        <li><b>Kitchen:</b> Only licensed volunteers are allowed in the kitchen for food preparation and serving.</li>
                                        <li><b>Emergency:</b> In case of a bite or emergency, seek help from the nearest volunteer immediately.</li>
                                        <li><b>Nighttime Safety:</b> Use a flashlight after dark and stay together for safety. Remember to wear closed-toe shoes at all times.</li>
                                        <li><b>Lights-Out:</b> Adhere to the lights-out rules and stay in your assigned dorms.</li>
                                    </ul>

                                    <Typography variant="body1" align="left"><b>OTHER RULES</b></Typography>
                                    <Typography variant="body2" align="left"><b>Clothing:</b></Typography>
                                    <ul align='left'>
                                        <li>Please make sure that all of your clothing is Mission appropriate both for western and Indian clothing</li>
                                        <li>No sleeve-less/spaghetti straps, no tank tops ( for boys and girls)</li>
                                        <li>No exposed belly area i.e crop tops, short tops (even for Indian clothes)</li>
                                        <li>No exposing butt area by tucking back shirt hem line in pants/ leggings</li>
                                        <li>Neckline must be modest</li>
                                        <li>For sports, shorts must be knee length or below (include boys). Leggings or full length athletic pants are recommended (we will be playing indoors)</li>
                                        <li>No make up/ skin care routine supplies</li>  
                                    </ul>
                                    <Typography variant="body2" align="left"><b>Class Etiquette:</b></Typography>
                                    <ul align='left'>
                                        <li>Attend all sessions; if sick, inform one of the Brahmacharins.</li>
                                        <li>Arrive 5 minutes early for each session and engage in nama-sankeerthanam while waiting.</li>
                                        <li>Avoid bathroom visits during sessions; attend to personal needs before or after.</li>
                                        <li>Store your shoes in the appropriate place.</li>                                          
                                    </ul>
                                    <Typography variant="body2" align="left"><b>Dome Guidelines:</b></Typography>
                                    <ul align='left'>
                                        <li>Refrain from dragging chairs on the basketball court to prevent damage to the flooring.</li>
                                        <li>Wear bare feet or shoes (during sports time) as instructed on the basketball court; no socks allowed.</li>
                                        <li>Open and close the dome doors gently to reduce noise.</li>
                                        <li>Rooms, loft, and stage are off-limits unless specific instructions are given.</li>
                                        <li>Maintain silence during classes and workshops held in the Dome.</li>                                          
                                    </ul>                                 
                                    <Typography variant="body2" align="left"><b>Dining area Etiquette:</b></Typography>
                                    <ul align='left'>
                                        <li>Chant brahma arpanam before meals.</li>
                                        <li>Follow instructions for food distribution and be patient and considerate.</li>
                                        <li>Do not leave the meal until Mangalaacharan is chanted or you are dismissed by instructors.</li>
                                        <li>Clean up after yourselves and leave the dining area clean.</li>
                                        <li>Avoid wasting food; stack dishes neatly.</li>                                          
                                    </ul>
                                    <Typography variant="body1" align="left"><b>Packing List</b></Typography>
                                    <Typography variant="body2" align="left"><b>Clothing: (Please read the rules about clothing above)</b></Typography>
                                    <ul align='left'>
                                        <li>Closed-toe shoes IS A MUST! (Mangalam facility requires you to be wearing closed-toe shoes at all times)</li>
                                        <li>Comfortable clothes to wear during classes (2 sets/day for 4 days)</li>
                                        <li>Indian Clothes (2-3 set)</li>
                                        <li>Sports clothing (4 days)</li>
                                        <li>Pajamas (2- 3 sets)</li>
                                        <li>Indian Clothes (2-3 set)</li>
                                        <li>Sports clothing (4 days)</li>
                                        <li>Pajamas (2- 3 sets)</li> 
                                        <li>Trash/Plastic bag to keep wet or dirty laundry</li>
                                        <li>1 windbreaker/rain jacket</li>
                                        <li>Socks  (2 sets/day for 4 days)</li>
                                        <li>Bathroom slippers/ water shoes (only to be used in the bathroom, not anywhere else)</li>
                                        <li>1 towels, 1 hand towel</li> 
                                        <li>Hat, sun glasses</li>
                                        <li>1* ONLY Clothes drying lines are available outside the dorms</li>                                        
                                    </ul>    
                                    <Typography variant="body2" align="left"><b>Toiletries:</b></Typography>
                                    <ul align='left'>
                                        <li>Soap/ Shampoo/ Conditioner</li>
                                        <li>Toothbrush, toothpaste</li>
                                        <li>simple Lotion/moisturizers (No make up/ skin care routine supplies)</li>
                                        <li>Deodorant</li>
                                        <li>UV protective lotions/sunscreen</li>
                                        <li>Personal first aid supplies</li>
                                        <li>Prescription medication (if applicable) - PLEASE NOTIFY ON-SITE DOCTOR</li>
                                        <li>Mosquito repellent</li>                                                                               
                                    </ul>
                                    <Typography variant="body2" align="left"><b>Supplies:</b></Typography>
                                    <ul align='left'>
                                        <li>Bedding: blanket, bed sheet, pillow, pillowcase in a trash bag (only mattress is provided)</li>
                                        <li>Hats/ Caps</li>
                                        <li>Refillable Water bottles</li>
                                        <li>Flashlight with new batteries</li>
                                        <li>Yoga Mat</li>
                                        <li>Umbrella/ Poncho (incase of outdoor activities in light rain)</li>
                                        <li>Notebook, pens or pencil</li>
                                        <li>Small bag/backpack to carry folder, notebook, water bottle around the retreat Grounds</li>                                                                               
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Paper>
            </React.Fragment>
        </div >
    );
}
