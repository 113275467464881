import React, { useState } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';


export default function ChildCareComp({ eventData }) {
    //const [addChild, setAddChild] = useState(false);
    const [addChild, setAddChild] = useState(eventData.parent.childCareReqd);
    const [inputFields, setInputFields] = useState(eventData.children);
    const [medicalFields, setMedicalFields] = useState(eventData.medical);
    console.log('remove: values length', addChild, inputFields, eventData.parent, eventData.children);


    const handleAddFields = () => {
        setInputFields([...inputFields, { memberType: "child", firstName: "", lastName: "", age: 0 }]);
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);       
        setInputFields(values);
        eventData.children = values;
        //if (index === 1) {console.log ("test1")} ;             
        console.log('remove: splice', values.length, index);
    };
    const handleChildChange = (e, index) => {
        let newForm = [...inputFields];
        newForm[index][e.target.name] = e.target.value;
        setInputFields(newForm);
        eventData.children = newForm; 
        console.log(JSON.stringify(inputFields));
        console.log(JSON.stringify(newForm));
        console.log("after handle child change: ", eventData);
    }
    function childCareSwitchHandler(e) {
        setAddChild(e.target.checked);
        eventData.parent.childCareReqd = e.target.checked;
        setInputFields([{ memberType: "child", firstName: "", lastName: "", age: 0 }]);
        eventData.children = [];
        console.log('childCareSwitchHandler : ' + inputFields.length);
    }
    function handleMedicalChange(e) {
        let newForm = { ...medicalFields };
        newForm[e.target.name] = e.target.value;
        setMedicalFields(newForm);
        eventData.medical = newForm;
    }

    return (
      <div>
        <Grid container direction="column">
          <Paper elevation={3}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  style={{ padding: "10px" }}
                  control={<Switch checked={addChild} />}
                  label="Need Child care ( Optional)"
                  size="small"
                  onChange={childCareSwitchHandler}
                  value={addChild}
                />
              </FormGroup>
            </Grid>

            <Grid item>
              {addChild &&
                inputFields.map((inputField, index) => (
                  <div key={index}>
                    <Grid item xs={12} sm={12}>
                      <Grid container align="left" spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <Paper elevation={3}>
                            <TextField
                              name="firstName"
                              label="Child First Name"
                              variant="outlined"
                              fullWidth
                              value={inputField.firstName}
                              onChange={(e) => handleChildChange(e, index)}
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Paper elevation={3}>
                            <TextField
                              name="lastName"
                              label="Child Last Name"
                              variant="outlined"
                              fullWidth
                              value={inputField.lastName}
                              onChange={(e) => handleChildChange(e, index)}
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Paper elevation={3}>
                            <TextField
                              name="age"
                              label="Age"
                              type="number"
                              variant="outlined"
                              fullWidth
                              inputProps={{ min: 1, max: 12 }}
                              value={inputField.age}
                              onChange={(e) => handleChildChange(e, index)}
                            />
                          </Paper>
                        </Grid>
                        <Grid item align="center" spacing={2}>
                          <IconButton onClick={() => handleRemoveFields(index)}>
                            <DeleteIcon />
                          </IconButton>
                          <IconButton onClick={() => handleAddFields(index)}>
                            <PersonAddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <div>
                      <br />
                    </div>
                  </div>
                ))}
            </Grid>
            <Grid item>
              {addChild && (
                <Grid item>
                  <Grid item xs={12} sm={12}>
                    <Paper
                      style={{
                        padding: "16px",
                        backgroundColor: "#673ab7",
                        color: "white",
                      }}
                      elevation={3}
                    >
                      <Typography variant="h7" align="left" gutterBottom>
                        <b>Release Authorization</b>
                      </Typography>
                    </Paper>
                    <div>
                      <br />
                    </div>
                    RELEASE OF LIABILITY - In consideration of Chinmaya Mission
                    granting the above named child permission to participate
                    in the event activities, I hereby assume all risks of
                    her/his personal injury that may result
                    from any event activity. As parent/guardian/responsible
                    party, I do indemnify, defend and hold harmless, Chinmaya
                    Mission and its officers, Volunteer parents and all
                    participants in the event program from and against all
                    liability, including claims and suits at law or in equity,
                    for injury, fatal or otherwise, which may result from any
                    negligence and/or the child taking part in activities.
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper elevation={3}>
                      <TextField
                        id="medicalConsentSignedBy"
                        required
                        fullWidth
                        label="Signature  (Type your Name) :"
                        name="medicalConsentSignedBy"
                        variant="outlined"
                        value={medicalFields.medicalConsentSignedBy}
                        onChange={handleMedicalChange}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </div>
    );
}